<template>
  <div ref="usageTableContainer" class="usage-detail__component-container" style="margin-bottom:-2rem;">
    <UsageDetailControls
      class="usage-detail__table-nav-controls"
      :selectedDate="monthYearSelected"
      :datePickerDisabledDates="disabledDates"
      :selectedDateSubText="selectedDateSubText"
      :showDateBackwardsButton="showDateBackwardsButton" 
      :showDateForwardsButton="showDateForwardsButton"
      :showJumpToDateButton="showJumpToDateButton" 
      :showGoToTodayButton="showGoToTodayButton"
      :showViewOptionsButton="false"
      :onDateBackwardsClicked="onDateBackwardsClicked" 
      :onDateForwardsClicked="onDateForwardsClicked"
      :onSelectedDateChanged="onSelectedDateChanged"
      :onGoToTodayClicked="onGoToTodayClicked"
      :onOptionSelectedTableSort="sortUsageData"
      :onOptionSelectedCalendarMonthsBillingPeriod="onOptionCalendarTypeSelected"
      :onViewOptionsClicked="onViewOptionsClicked"
      :datePickerDateType="'month'" 
      :selectConfigTableSort="selectConfigTableSort"
      :selectConfigCalendarMonthsBillingPeriod="selectConfigCalendarMonthsBillingPeriod"
      :disableDateNavigationButtonBack="disableDateNavigationButtonBack"
      :disableDateNavigationButtonForward="disableDateNavigationButtonForward"
      :disableAllDateNavControls="!loadState  || disableAllDateNavControls"
    />
    <div v-if="!loadState" class="table-loading" style="min-height: 400px"></div>
    <div class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <div class="usage-detail__daily-table" ref="UsageTableDaily">
      <section v-if="loadState === 'complete' || loadState === 'unavailable'" class="usage-detail__table-container">
        <table class="usage-detail__table-rows-wrapper">
          <thead>
            <tr>
            <th class="date">Date</th>
            <th><div class="day-of-week">Day <span class="gds-desktop-only">of Week</span></div></th>
            <th v-if="isNetMetered">Net (kWh)</th>
            <th class="grow-basis-20" v-if="isNetMetered">Total Home Consumption</th>
            <th class="grow-basis-20" v-if="isNetMetered">Total Generation</th>
            <th class="grow-basis-20" v-if="isNetMetered && !hasTOU">Consumed from Grid</th>
            <th class="grow-basis-20" v-if="!isNetMetered && !hasTOU">Consumption</th>
            <th class="grow-basis-20" v-if="hasTOU">Time Of Use</th>
            <th class="grow-basis-20" v-if="hasEvCharger">EV Charger</th>
            <th v-if="hasSnapshotData && !isNetMetered">Highest Use Time of Day</th>
            <th>Low Temp.</th>
            <th>High Temp.</th>
            <th>Conditions</th>
            </tr>
          </thead>
          <tbody>
          <template v-for="usage in dailyUsageData">
              <Usage-Detail-Table-Row 
                v-bind:key="`daily-${usage.date.getTime()}`"
                :dailyUsagePeriodValues="usage.dailyUsagePeriodValues"
                :expandedRow="findDate(usage.date)"
                :usage="usage"
                :hasGenerationRecordsPresent="hasGenerationRecordsPresent"
                :maxUsageValue="maxUsageValue"
                :isNetMetered="isNetMetered"
                :hasTOU="hasTOU"
                :hasEvCharger="hasEvCharger"
                :hasSnapshotData="hasSnapshotData"
                @toggle-hourly="toggleHourlyUsage" 
              />
          
            <tr v-if="findDate(usage.date) != -1" :key="`hourly-${usage.date.getTime()}`" class="hourly-cell" >
              <td colspan="100%">
                <div class="usage-detail__hourly-table-heading">
                  <h3>Hourly for {{usage.date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}}</h3>
                </div>
              <Usage-Detail-Hourly-Table 
                :usageDate="usage.date"
                :hasGenerationRecordsPresent="hasGenerationRecordsPresent" 
                :isNetMetered="isNetMetered"
                :hasTOU="hasTOU"
                :hasEvCharger="hasEvCharger"
              />
                  <div class="usage-detail__hourly-table-footer-buttons"><button class="gds-button gds-button-outline gds-compact gds-round" @click="toggleHourlyUsage(usage.date)">Close Hourly</button></div>

              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
import { endOfMonth, isEqual, parseISO, startOfDay, isSameDay, addMinutes, addDays, isToday, isFuture } from "date-fns";
import { DumpError, ToServerDateTruncate } from "../../../../utilities";
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls";
import ButtonViewOptions from "../../../generic/ButtonViewOptions";
import HighestUsageMicroSparkbar from "../highestusagemicrosparkbar/HighestUsageMicroSparkbar";
import UsageDetailHourlyTable from "./UsageDetailHourlyTable";
import UsageDetailTableRow from "./UsageDetailTableRow";
import UsageTableUtil from "../../../mixins/UsageTableUtil";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import { GetComponentStatus } from '../../../../services/statuspage';
import GMPAPI from "../../../../services/gmpapi";


export default {
  name: "UsageDetailTable",
  mixins: [
    MediaQueryMixin,
    UsageTableUtil,
    UsageDetailBaseComponentMixin
  ],
  components: {
    UsageDetailControls,
    ButtonViewOptions,
    HighestUsageMicroSparkbar,
    UsageDetailHourlyTable,
    UsageDetailTableRow
  },
  data() {
    return {
      dailyUsageData: undefined,
      hasGenerationRecordsPresent: undefined,
      hourlyUsageSelections: [],
      hourlyUsageLoadState: undefined,
      hasSnapshotData: false,
      maxUsageValue: undefined,
      disableDateNavigationButtonForward: undefined,
      timeOfDayMap: [
        { "hour0to3": "12-4AM" },
        { "hour4to7": "4-8AM" },
        { "hour8to11": "8AM-Noon" },
        { "hour12to15": "Noon-4PM" },
        { "hour16to19": "4-8PM" },
        { "hour20to23": "8PM-12AM" }],
      selectConfigTableSort: {
        width: undefined,
        buttonAppearance: true,
        options: [
          {
            label: 'Newest First',
            value: 'newest-first'
          },
          {
            label: 'Oldest First',
            value: 'oldest-first'
          }
        ]
      },
      selectedTableSortValue: 'newest-first',
    }
  },
  watch: {
    currentAccount() {
      this.init();
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isNetMetered() {
      return this.$store.state.user.isNetMetered || this.dailyUsageData.some(item => item.hasOwnProperty("generation"));
    },
    formattedDate(){
      return this.selectedStartDate.toUsEasternISOString().split('T')[0];
    },
    hasTOU() {
      return this.dailyUsageData.some(item => 
        item.hasOwnProperty("onPeak") ||
        item.hasOwnProperty("offPeak") &&
        item.hasOwnProperty("consumedTotal")
      );
    },
    hasEvCharger() {
      return this.dailyUsageData.some(item =>
        item.hasOwnProperty('evPeakConsumption') &&
        item.hasOwnProperty('evOffPeakConsumption')
      );
    },
  },
  async mounted() {
    try {
      // Before we do anything else, check to see if component is operational
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        this.disableAllDateNavControls = true;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      } 
      
      this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(this.billingPeriods.periods);
      await this.init();
      
    } catch (err) {
      DumpError("Usage detail table refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  methods: {
    async adjustDatesForBillingPeriod() {
      try {
        this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
        let billingPeriodDates = await this.findBillingPeriodDates(this.selectedStartDate);
        if (isToday(this.selectedStartDate) || isFuture(this.selectedStartDate)) {
          billingPeriodDates = this.findBillingPeriodDates(subDays(this.selectedStartDate, 1));
        }
        if (billingPeriodDates === null) {
          this.selectedStartDate = startOfMonth(this.selectedStartDate);
          this.selectedEndDate = endOfMonth(this.selectedStartDate);
        }
      } catch (err) {
        this.handleRefreshError(err);
        return;
      }

      if (!this.dateControlsTouched && this.periodTypeSelected === 'billing-period') {
        const currentPeriod = this.billingPeriods.periods.find(period => period.current === true);

        if (currentPeriod) {
          this.selectedStartDate = parseISO(currentPeriod.startDate);
          this.selectedEndDate = parseISO(currentPeriod.endDate);
        }
      }

      this.updateDateSubText();
      this.setDateNavigationButtonStatus();
      this.refreshData(this.currentAccount.accountNumber, this.selectedStartDate, this.selectedEndDate);
    },
    async init() {
      this.queryParams = this.$route.query;
      this.showQueryParams = true;
      const currentDate = new Date();

      //if we have a start date in the query params, validate it and set up the month start/end dates 
       if (this.queryParams.startDate ) {
          let urlDate = new Date(this.queryParams.startDate);
          if (!isNaN(urlDate.getTime())) {
            this.selectedStartDate = addDays(urlDate, 2);
            this.selectedEndDate = endOfMonth(this.selectedStartDate);
          }

      } else {
        this.selectedEndDate = endOfMonth(currentDate);

      }
          
      //determine if we have billing periods or calendar months specified in the query params
      if (this.queryParams && this.queryParams.periodType && this.queryParams.periodType == "billing-period") {
        this.periodTypeSelected = "billing-period";
        this.selectConfigCalendarMonthsBillingPeriod.selectedValue = "billing-period";
        this.adjustDatesForBillingPeriod();
      } else {
        this.periodTypeSelected = "calendar-month";
        this.selectConfigCalendarMonthsBillingPeriod.selectedValue = "calendar-month";
        this.adjustDatesForCalendarMonth();
      }
      this.setControlWidths();

    },
    async refreshData(accountNumber, startDate, endDate) {
      this.resetData();
      const targetRoute = {
              query: {
                  startDate: this.formattedDate,
                  periodType: this.periodTypeSelected
              }
          };

          // Check if the target route is different from the current route
          if (this.$router.currentRoute.fullPath !== this.$router.resolve(targetRoute).href) {
              this.$router.replace(targetRoute).catch(err => {
                  if (err.name !== 'NavigationDuplicated') {
                      console.error(err);
                  }
              });
          }
      try {
        const response = await this.getPeriodUsageData(accountNumber, "daily", startDate, endDate);
        if (response && response.intervals[0] && response.intervals[0].values && response.intervals[0].values.length > 0) {
          const dailyUsageData = response.intervals[0].values;
          const dailyUsageWithWeatherIcon = dailyUsageData.map(item => {
            if (item && (item.temperature || item.darkskyTemperature)) {
              return { ...item, weatherIconImageUrl: this.getWeatherIcon(item.weatherIcon) };
            }
            return { ...item, weatherIconImageUrl: "" };
          });
          this.dailyUsageData = dailyUsageWithWeatherIcon;
          this.loadState = 'complete';
        } else {
          this.loadState = 'empty';
          return;
        }
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        return;
      }

      this.hasGenerationRecordsPresent = this.checkForUsageRecordType(this.dailyUsageData, this.GENERATION);
      this.dailyUsageData = this.filterRowsGreaterThanDate(this.dailyUsageData, new Date());

      this.maxUsageValue = this.findMaxUsageValue(this.dailyUsageData);
      this.sortUsageTable(this.selectedTableSortValue, this.dailyUsageData);
      await this.updateSnapshotData(accountNumber, startDate, endDate);

    },

    resetData() {
      this.loadState = undefined;
      this.hasSnapshotData = false;
      this.dailyUsageData = [];
      this.dailyUsageSnapshotData = null;
    },

    filterRowsGreaterThanDate(array, date) {
      return array.filter((row) => row.date < date);
    },

    sortUsageTable(sortOrder, arrayToSort) {
      
      return arrayToSort.sort((a, b) => {
        if (sortOrder === 'oldest-first') {
          return a.date - b.date;
        }
        return b.date - a.date;
      });
    },
    async getAccountUsageSnapshot(accountNumber, startDate, endDate, expand) {
      return await GMPAPI.GetAccountUsageSnapshot(accountNumber, ToServerDateTruncate(startDate), ToServerDateTruncate(endDate), expand);
    },

    async updateSnapshotData(accountNumber, startDate, endDate) {
      try {
        this.dailyUsageSnapshotData = await this.getAccountUsageSnapshot(accountNumber, startDate, endDate, 'daily');
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        this.hasSnapshotData = false;
        return;
      }

      if (this.dailyUsageSnapshotData && this.dailyUsageSnapshotData.dailyConsumption && 
          this.dailyUsageSnapshotData.dailyConsumption.length > 0) {
        this.hasSnapshotData = true;
        this.appendTimeOfUseData(this.dailyUsageData, this.dailyUsageSnapshotData.dailyConsumption);
      } else {
        this.hasSnapshotData = false;
      }
    },
    
    appendTimeOfUseData(dailyUsageData, usageSnapshotData) {
      // Add additional dailyUsagePeriodValues and highestUsagePeriodOfDay properties from usage snapshot data to dailyUsage array

      dailyUsageData.forEach(dailyUsage => {
        const snapshotTimeOfUse = usageSnapshotData.find(item => {
          const parsedItemDateUTC = parseISO(item.date);
          const parsedItemDateLocal = addMinutes(parsedItemDateUTC, parsedItemDateUTC.getTimezoneOffset());

          return isSameDay(startOfDay(parsedItemDateLocal), dailyUsage.date);
        });

        if (snapshotTimeOfUse) {
          const highestUsagePeriodOfDay = this.findSnapshotHourWithHighestUsage(snapshotTimeOfUse.values);
          dailyUsage.highestUsagePeriodOfDay = this.findUsagePeriodDisplayText(highestUsagePeriodOfDay);
          dailyUsage.dailyUsagePeriodValues = this.getSnapshotTimePeriodValues(snapshotTimeOfUse.values);
        }
      })
    },

    findSnapshotHourWithHighestUsage(dailyTimeOfDayUsageValues) {
      const maxHourProperty = Object.keys(dailyTimeOfDayUsageValues).reduce((maxKey, currentKey) => {
        if (currentKey.startsWith("hour") && dailyTimeOfDayUsageValues[currentKey] > dailyTimeOfDayUsageValues[maxKey]) {
          return currentKey;
        }
        return maxKey;
      });
      return maxHourProperty;
    },

    findUsagePeriodDisplayText(hour) {
      const match = this.timeOfDayMap.find(obj => Object.keys(obj)[0] === hour);
      return match ? Object.values(match)[0] : null;
    },

    getSnapshotTimePeriodValues(data) {
      return Object.keys(data)
        .filter(key => key.startsWith("hour"))
        .map(key => data[key]);
    },

    handleDailyUsageRead(value) {
      this.hourlyUsageLoadState = value;
    },

    getUniqueUsageTypes(usageData) {
      const uniqueProperties = new Set();

      usageData.forEach(obj => {
        if (obj.hasOwnProperty(this.GENERATION)) {
          uniqueProperties.add(this.GENERATION);
        }
        if (obj.hasOwnProperty(this.CONSUMPTION)) {
          uniqueProperties.add(this.CONSUMPTION);
        }
        if (obj.hasOwnProperty(this.ON_PEAK)) {
          uniqueProperties.add(this.ON_PEAK);
        }
      });

      return Array.from(uniqueProperties);
    },

    sortUsageData(sortOrder) {
      if (sortOrder === 'oldest-first') {
        this.selectedTableSortValue = 'oldest-first';
      }
      else {
        this.selectedTableSortValue = 'newest-first';
      }
      this.dailyUsageData = this.sortUsageTable(sortOrder, this.dailyUsageData);
      this.$router.push({ query: { startDate: this.formattedDate, periodType: this.periodTypeSelected, sort: this.selectedTableSortValue} });

    },

    toggleHourlyUsage(usageDate) {
      document.querySelector(".usage-detail__table-rows-wrapper").scrollLeft = 0;
      const index = this.findDate(usageDate);

      if (index != -1) {
        this.hourlyUsageSelections.splice(index, 1);
      } else {
        this.hourlyUsageSelections.push(usageDate);
      }
    },

    findDate(usageDate) {
      const isFoundIndex = this.hourlyUsageSelections.findIndex(item => {
        return isEqual(startOfDay(item), startOfDay(usageDate));
      });
      return isFoundIndex;
    },
    setControlWidths() {
      //TODO: Set controls based on query parameters.
      this.selectConfigCalendarMonthsBillingPeriod = JSON.parse(JSON.stringify(this.selectConfigCalendarMonthsBillingPeriod));
      this.selectConfigTableSort = JSON.parse(JSON.stringify(this.selectConfigTableSort));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>